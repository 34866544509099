/* App.css */

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #1b1b1b;
  color: #fff;
}

.App {
  text-align: center;
}

h1 {
  color: #5db5f2;
  font-size: 3em;
}

/* Auth Container */
.auth-container {
  margin: 50px auto;
  width: 80%;
  max-width: 500px;
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
}

/* Tabs Styling */
.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs button {
  margin: 0 5px;
  padding: 10px;
  background-color: #5db5f2;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.tabs button:hover {
  background-color: #4aa1d6;
}

/* Logout Button Styling */
.logout-btn {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  cursor: pointer;
}

.logout-btn:hover {
  background-color: #e53935;
}

/* Form Styles */
.auth-form {
  margin-top: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #5db5f2;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #4aa1d6;
}

/* Wallets List */
.wallet-container {
  margin-top: 20px;
}

.wallet-item {
  background-color: #3a3a3a;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-item button {
  background-color: #ff5722;
}

.wallet-item button:hover {
  background-color: #e64a19;
}

.new-wallet {
  margin-top: 15px;
  font-weight: bold;
  color: #5db5f2;
}

.signature {
  margin-top: 15px;
  color: #ffcc00;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .App {
      font-size: 14px;
  }

  h1 {
      font-size: 2.5em;
  }
}
